import request from "../utils/request"

const apis = {
    GetMyIndexData: '/api/MyLesson/GetMyIndexData',
    GetChartsData: 'api/MyLesson/GetChartsData'
}

/**
 * 获取我的首页数据
 * @param {*} parameter 
 */
export function getMyIndexData(parameter){
    return request({
        url: apis.GetMyIndexData,
        method: 'post',
        data: parameter
    })
}

/**
 * 统计接口
 * @param {*} parameter 
 */
export function getChartsData(parameter){
    return request({
        url: apis.GetChartsData,
        method: 'post',
        data: parameter
    })
}